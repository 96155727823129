const urlName = "/standard-saas/v1";
export default (API) => ({
  // 发送评论
  interactSave(data) {
    return API({
      //学习列表
      url: `${urlName}/interact/save`,
      method:'post',
      data
    });
  },
    // 评论列表
    findUserList({data,params}){
        return API({
          //学习列表
          url: `${urlName}/interact/findUserList`,
          method:'post',
          params,
          data
      });
    },
    // 点赞和取消点赞
    saveLikes({data,params}){
      return API({
          //学习列表
          url: `${urlName}/interact/saveLikes`,
          method:'post',
          params,
          data
      });
    }
});
